// const BASE_URL = "http://192.168.9.7:5002/node_api";

const BASE_URL = "https://adminaadhil.iossmlm.com/node_api";
const HOSTING = "DEMO";

// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = "sadkjchfajsxhadsycxgasdcgiaskdc";
const PAYPAL_CLIENT_ID =
    "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";
const projectId = "e2c91ccb305f53c2fcdb624478bcd061";

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID, HOSTING, projectId };
